
import { Component, Prop, Vue } from 'vue-property-decorator';
import { formatDate } from '@/services/formatService';

@Component<DateTableElementComponent>({})
export default class DateTableElementComponent extends Vue {
  @Prop()
  date!: string | Date;

  format = formatDate;
}
